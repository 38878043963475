import Vue from "vue";
import {
  ValidationProvider, ValidationObserver, extend, configure, localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

configure({
  useConstraintAttrs: false,
});

localize("es", es);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("url", {
  validate: (value) => {
    if (value) {
      const regex = RegExp(
        "(https?:\\/\\/|http:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$",
        "i",
      );
      return !!regex.test(value);
    }

    return false;
  },
  message: "El campo {_field_} no es una url válida",
});

extend("password_strong", {
  message:
    "El campo {_field_} debe contener al menos: 1 letra mayúscula, 1 letra minúscula, 1 número y un carácter especial (ejemplo: , . _ & ? etc)",
  validate: (value) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return strongRegex.test(value);
  },
});

extend("only_number_letters", {
  message: "El campo {_field_} solo debe contener números, letras y espacios en blanco",
  validate: (value) => {
    const regexExpression = new RegExp("^[A-Za-z0-9 &]*[A-Za-z0-9][A-Za-z0-9 &]*$");
    return regexExpression.test(value);
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
