import Vue from "vue";
import { showErrors } from "@/plugins/utils";
import VueMask from "v-mask";
import Toast from "vue-toastification";
// import VueFbCustomerChat from "vue-fb-customer-chat";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import auth from "./plugins/auth";

// Vue.use(VueFbCustomerChat, {
//   page_id: 1493170934312373,
//   theme_color: "#DB2723",
//   locale: "es_ES",
// });

Vue.prototype.$auth = auth;

Vue.use(Toast, {});

require("./plugins/validate");
require("./plugins/axios");

Vue.use(VueMask);
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    showErrors,
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
