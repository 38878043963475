const auth = [
  {
    path: "/",
    name: "Home",
    // redirect: "/login-email",
    component: () => import("../views/LoginEmail.vue"),
  },
  {
    path: "/login/verificar-codigo",
    name: "VerifyLoginCode",
    component: () => import("../views/VerifyLoginCode.vue"),
  },
  {
    path: "/login-email",
    name: "LoginEmail",
    component: () => import("../views/LoginEmail.vue"),
  },
  {
    path: "/registro",
    name: "Register",
    redirect: "/registro-con-email",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/registro-con-email",
    name: "RegisterEmail",
    component: () => import("../views/RegisterEmail.vue"),
  },
  {
    path: "/verificar-codigo",
    name: "CheckCode",
    component: () => import("../views/CheckCode.vue"),
  },
  {
    path: "/recuperar-mi-contrasena",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/auth/validator",
    name: "VerifyEmail",
    component: () => import("../views/VerifyEmail.vue"),
  },
  {
    path: "/autologin",
    name: "AutoLogin",
    component: () => import("../views/AutoLogin.vue"),
  },
];

export default auth;
