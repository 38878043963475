import Dashboard from "@/layouts/Dashboard.vue";

const profile = [
  {
    path: "/dashboard/profile",
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: "profile",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Profile.vue"),
        children: [
          {
            path: "/",
            name: "Profile",
            component: () => import(/* webpackC hunkName: "dashboard" */ "@/components/profile/Profile.vue"),
          },
          {
            path: "/dashboard/profile/ChangePassword/",
            name: "ChangePassword",
            component: () => import(/* webpackChunkName: "dashboard" */ "@/components/profile/ModalChangePassword.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard/profile/profileDelete",
    name: "ProfileDelete",
    meta: {
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/DeleteAccount.vue"),
  },
  {
    path: "/dashboard/profile/profileDeleteCode",
    name: "ProfileDeleteCode",
    meta: {
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/DeleteAccountCode.vue"),
  },
];

export default profile;
