import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import auth from "@/plugins/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    countries: [],
    loading: false,
    user: auth.user(),
    openModalChangePassword: false,
    domainSelected: "",
  },
  getters: {
    countries(state) {
      return state.countries;
    },
    openModalChangePassword(state) {
      return state.openModalChangePassword;
    },
  },
  mutations: {
    SET_OPEN_MODAL_CHANGE_PASSWORD(state, payload) {
      state.openModalChangePassword = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
    REFRESH_USER_DATA(state) {
      state.user = auth.user();
    },
    SET_DOMAIN_SELECTED(state, payload) {
      state.domainSelected = payload;
    },
  },
  actions: {
    SET_OPEN_MODAL_CHANGE_PASSWORD(context, payload) {
      context.commit("SET_OPEN_MODAL_CHANGE_PASSWORD", payload);
    },
    REFRESH_USER_DATA(context) {
      context.commit("REFRESH_USER_DATA");
    },
    SET_LOADING(context, payload) {
      context.commit("SET_LOADING", payload);
    },
    SET_COUNTRIES(context, payload) {
      context.commit("SET_COUNTRIES", payload);
    },
    LOAD_COUNTRIES(context) {
      axios.get("auth/login/tables").then((response) => {
        const { data } = response;
        context.commit("SET_COUNTRIES", data.countries);
      });
    },
    ON_LOGIN(context, payload) {
      auth.setToken(payload);
      axios.defaults.baseURL = `${process.env.VUE_APP_API_URL_BASE}dashboard`;
      context.commit("REFRESH_USER_DATA");
    },
    async ON_AUTO_LOGIN(context, payload) {
      const data = {
        domain: payload,
      };
      await axios.post("projects/autologin", data).then((response) => {
        window.open(response.data.data, "_BLANK");
      });
    },
    async ON_AUTO_LOGIN_DEMO(context, payload) {
      const data = {
        domain: payload,
      };
      await axios.post("projects/autologin", data).then((response) => {
        context.dispatch("ON_GET_CREDENTIALS", response.data.data);
      });
    },
    async ON_GET_CREDENTIALS(context, payload) {
      axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
      axios.defaults.headers["Content-Type"] = "application/json";
      await axios.get(payload).then((response) => {
        context.commit("SET_DOMAIN_SELECTED", response.data.data);
      });
    },
  },
  modules: {},
});
