<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen">
        </div>
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel" v-bind:class="{'small-panel': onlyIconsSee}">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
import { store, mutations } from '@/store/store';

export default {
  data: () => ({
    onlyIcons: true,
  }),
  methods: {
    closeSidebarPanel: mutations.toggleNav,
  },
  computed: {
    isPanelOpen() {
      return store.isNavOpen;
    },
  },
  watch: {
    isShow(newVal) {
      this.onlyIcons = newVal;
    },
  },
  props: {
    onlyIconsSee: Boolean,
  },
};
</script>
