<template>
    <div id="burger"
         :class="{ 'active' : isBurgerActive }" style="padding-block: 12px; padding-inline: 14px;"
         >
        <slot>
            <button type="button" class="burger-button" title="Menu" @click.prevent="toggle" style="width: 24px; height: 24px; margin-right: 8px;">
                <img src="@/assets/images/burger.svg" alt="logo-large" v-if="!isBurgerActive" >
                <img src="@/assets/images/icon-close.svg"  alt="logo-large" v-if="isBurgerActive"  />
            </button>
            <img @click="redirectToDashboard" src="@/assets/images/logo-large.svg" style="height: 24px;" alt="logo-large" v-if="!isBurgerActive">
        </slot>
    </div>
</template>
<script>
import { store, mutations } from '@/store/store';

export default {
  computed: {
    isBurgerActive() {
      return store.isNavOpen;
    },
  },
  methods: {
    toggle() {
      mutations.toggleNav();
    },
    redirectToDashboard() {
      // Utiliza $router.push para redireccionar a la ruta deseada
      this.$router.push('/dashboard/');
    },
  },
};
</script>
