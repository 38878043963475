<template>
  <div class="dashboard">
    <!-- <Topbar></Topbar> -->
    <!-- <Sidebar></Sidebar> -->
    <!-- <Burger></Burger> -->
    <div class="dashboard__body" v-bind:class="{ 'grid-hide': onlyIcons, ...computedClasses }" v-if="user.isValidated">
      <burger class="burger"></burger>
      <Sidebar v-bind:onlyIconsSee="onlyIcons" style="display: relative;" v-if="user.isValidated" class="sidebar">
        <div >
          <img src="@/assets/images/logo-large.svg" alt="Logo de Geor Company" class="img-fluid cursor-pointer sidebar-logo sidebar-logo-large" width="30"
             @click="redirectToDashboard" />
      <img src="@/assets/images/geor-icon.svg" alt="Logo de Geor Company" class="img-fluid cursor-pointer sidebar-logo sidebar-logo-mini" width="30"
      style="" @click="redirectToDashboard" />
        <ul class="sidebar-panel-nav" style="margin-top: 24px;">
          <li  @click="setOnlyIcons(false)">
            <router-link :to="{ name: 'Dashboard' }"  class ="label-nav" :class="{'onlyIcon': onlyIcons,...computedClassesActive('/dashboard/')}">
              <img src="@/assets/images/company.svg" alt="Logo de Geor Company" class="img-fluid filter icon" width="24"
                style="margin-right: 10px;min-width: 24px;" />
              <img src="@/assets/images/company-fill.svg" alt="Logo de Geor Company" class="img-fluid filter icon-fill" width="24"
                style="margin-right: 10px;min-width: 24px;" />
              <span  v-bind:class="{ 'hide-label': onlyIcons }" style="font-size: 14px!important; font-style: normal;">Empresas</span>
            </router-link>
          </li>
          <li @click="setOnlyIcons(false)">
            <router-link  :to="{ name: 'Help' }" :class="{'onlyIcon': onlyIcons,...computedClassesActive('/dashboard/help/')}">
              <img src="@/assets/images/help.svg" alt="Logo de Geor Company" class="img-fluid filter icon" width="24"
                style="margin-right: 10px; min-width: 24px;" />
              <img src="@/assets/images/help-fill.svg" alt="Logo de Geor Company" class="img-fluid filter icon-fill" width="24"
                style="margin-right: 10px; min-width: 24px;" />
              <span v-bind:class="{ 'hide-label': onlyIcons }" style="font-size: 14px!important;  font-style: normal;">Ayuda</span>
            </router-link>
          </li>
          <li @click="setOnlyIcons(true)">
            <router-link :to="screenWidth < 699 ? { name: 'ProfileMobile' } : { name: 'Profile' }"
            :class="{ 'onlyIcon': onlyIcons,...computedClassesActive('/dashboard/profile/profile/','/dashboard/profile/ChangePassword'),...computedClassesActive('/dashboard/profileDashboard/mobile')}"
              style="gap: 10px;">
              <img :src="user.avatar" alt="Administrador" width="24" class="img-circle" />
              <span v-bind:class="{ 'hide-label': onlyIcons }" style="font-size: 14px !important; font-style: normal;">Mi perfil</span>
            </router-link>
          </li>
        </ul>
        </div>
        <div class="close-session" @click="onLogout">
          <div style="padding: 12px; display: flex;align-items: center;padding-block: 8px;" >
            <img src="@/assets/images/off.svg" alt="Logo de Geor Company" class="img-fluid filter" width="24"
                style="margin-right: 10px;min-width: 24px;"/>
            <span v-bind:class="{ 'hide-label': onlyIcons }" style="font-size: 14px!important; font-style: normal;">Cerrar sesión</span>
          </div>
        </div>
      </Sidebar>
      <div v-if="user" class="dashboard__body-content" :class="{
        'dashboard__body-content-is-not-verified': !user.isValidated, 'small-panel1':onlyIcons
      }">
        <router-view v-if="user.isValidated"/>
      </div>
    </div>
    <div class="dashboard__body-no-validate" v-if="!user.isValidated">
      <div  style="display: flex; align-items: center; flex-direction: column;">
        <div class="auth__logo" style="margin-top: 16px; margin-bottom: 16px;">
        <img src="@/assets/images/logo.svg" alt="Geor, iniciar sesión" />
      </div>
          <div style="text-align: left; border: 1px solid rgba(238, 238, 238, 1);padding: 30px;">
            <div>
              <img src="@/assets/images/danger-icon.svg" alt="Geor, iniciar sesión" style="width: 48px;"/>
            </div>

            <h2 style="font-size: 18px !important; margin-bottom: 4px;">Verifica tu correo</h2>
            <p style="font-size: 14px;">Para verificar tu correo sigue los siguientes<br> <span>pasos:</span></p>
            <div style="text-align: left;">
            <p style="margin: 0px; font-size: 14px;">1. Abre tu correo <span style="color: #0375F8">{{ user.email }}</span></p>
            <p style="margin: 0px; font-size: 14px;">2. Abre el correo enviado por Geor</p>
            <p style="margin: 0px; font-size: 14px;">3. Dar clic en el botón <span style="font-weight: 700;">"Validar mi cuenta"</span></p>
            </div>
          </div>
          <div style="margin-top: 24px;">
            <p style="font-size: 14px;">¿No recibiste el correo? <span @click.prevent="onResendEmailVerification"
              style="color: #0375F8;cursor: pointer; text-decoration: underline;">Reenviar</span></p>
          </div>
          <div  :disabled="loading" style="padding-block: 10px; height: 40px; color: #0375F8;
      padding-inline: 22px; cursor: pointer;" @click="onChangeAccount">
          <small style="font-size: 14px !important;">VOLVER A INICIAR SESIÓN</small>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Topbar from "@/components/partials/Topbar.vue";
import { mapState } from "vuex";
import { store, mutations } from '@/store/store';
import Burger from '../views/dashboard/components/menu/Burger.vue';
// import Sidebar from "@/components/partials/Sidebar.vue";
// import Burger from "../views/dashboard/components/menu/Burger.vue";
import Sidebar from "../views/dashboard/components/menu/Sidebar.vue";

export default {
  data: () => ({
    onlyIcons: false,
    screenWidth: window.innerWidth,
  }),
  components: {
    // Topbar,
    // Burger,
    Sidebar,
    Burger,
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  created() {
    this.$axios.get("user").then((response) => {
      this.$auth.saveUser(response.data.data);
      this.$auth.user();
      mapState({
        user: (state) => state.user,
        loading: (state) => state.loading,
      });
    });
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loading: (state) => state.loading,
    }),
    computedClasses() {
      return {
        'small-panel': this.isSpecificRoute(),
        'small-panel1': this.isSpecificRoute(),
      };
    },
    computedClassesActive() {
      return (param1, param2 = null) => {
        const classes = {};
        if (this.isActiveHover(param1)) {
          classes['nav-var-selected'] = true;
        }
        if (param2 && !classes['nav-var-selected'] && this.isActiveHover(param2)) {
          classes['nav-var-selected'] = true;
        }

        return classes;
      };
    },
  },
  watch: {
    $route: {
      handler() {
        this.updateOnlyIcons();
      },
      immediate: true,
    },
  },
  methods: {
    toggle() {
      mutations.toggleNav();
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      this.redirectBasedOnScreenWidth();
    },
    redirectToDashboard() {
      // Utiliza $router.push para redireccionar a la ruta deseada
      this.$router.push('/dashboard/');
      if (window.screen.width <= 699) {
        this.toggle();
      }
    },
    onResendEmailVerification() {
      this.$axios.post("user/resend-verification-email").then((response) => {
        const { data } = response;
        this.$toast.success(data.message);
      });
    },
    setOnlyIcons(onlyIcons) {
      if (window.screen.width >= 699) {
        this.onlyIcons = onlyIcons;
      }

      if (window.screen.width <= 699) {
        store.isNavOpen = false;
        document.querySelector("body").classList.remove('no-scroll');
      }
    },
    updateOnlyIcons() {
      // Reemplaza '/ruta-especifica' con la ruta que deseas verificar
      if (window.screen.width >= 699) {
        this.onlyIcons = this.$route.path === '/dashboard/profile/profile/' || this.$route.path === '/dashboard/profile/ChangePassword';
      }
      if (window.screen.width <= 699) {
        this.onlyIcons = false;
      }
    },
    isSpecificRoute() {
      // Reemplaza '/ruta-especifica' con la ruta que deseas verificar
      return this.$route.path === '/dashboard/profile/profile/' || this.$route.path === '/dashboard/profile/ChangePassword';
    },
    isActiveHover(route) {
      return this.$route.path === route;
    },
    onLogout() {
      this.$axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
      this.$axios.post("auth/logout").then((response) => {
        this.$auth.removeToken();
        this.$toast.success(response.data.message);
        // this.$router.push({ name: "Home" });
        window.location.href = "https://geor.app/";
      });
    },
    onChangeAccount() {
      this.$axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
      this.$axios.post("auth/logout").then(() => {
        this.$auth.removeToken();
        // this.$toast.success(response.data.message);
        // this.$router.push({ name: "Home" });
        window.location.href = "/login-email";
      });
    },

    redirectBasedOnScreenWidth() {
      if (this.screenWidth < 699) {
        this.onlyIcons = false;
      } else if (this.$route.path === '/dashboard/profile/profile/' || this.$route.path === '/dashboard/profile/ChangePassword') {
        this.onlyIcons = true;
      }
    },
  },
};
</script>
