import Dashboard from "@/layouts/Dashboard.vue";

const dashboard = [
  {
    path: "/profileDashboard",
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: "/dashboard/profileDashboard/mobile/",
        name: "ProfileMobile",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/ProfileDashboard.vue"),
      },
    ],
  },
];

export default dashboard;
