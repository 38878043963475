import Vue from "vue";

export const store = Vue.observable({
  isNavOpen: false,
});

export const mutations = {
  toggleNav() {
    store.isNavOpen = !store.isNavOpen;
    if (!document.querySelector("#burger").classList.contains('active')) {
      document.querySelector("body").classList.add('no-scroll');
    } else {
      document.querySelector("body").classList.remove('no-scroll');
    }
  },
};

// Función para actualizar isNavOpen según el tamaño de la pantalla
function updateNavState() {
  store.isNavOpen = window.innerWidth > 699;
}

// Escuchar el evento de cambio de tamaño de la ventana y actualizar isNavOpen
window.addEventListener('resize', updateNavState);

// Llamar a updateNavState al cargar la página para establecer el valor inicial
updateNavState();
