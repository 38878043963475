import Dashboard from "@/layouts/Dashboard.vue";

const help = [
  {
    path: "/dashboard/help",
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Help",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Help.vue"),
      },
    ],
  },
];

export default help;
