import Vue from "vue";
import axios from "axios";
import store from "../store";
import auth from "./auth";
import router from '../router';

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
};

if (auth.check()) {
  const token = auth.getToken();
  store.dispatch('ON_LOGIN', token);
} else {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch("SET_LOADING", true);
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch("SET_LOADING", false);
    return response;
  },
  (error) => {
    const { status } = error.response;
    const { data } = error.response;
    if (status === 403) {
      Vue.$toast.warning("No estas autorizado para entrar a esta sección.");
      router.push({ name: 'Home' });
    } else if (status === 404) {
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        Vue.$toast.warning(data.message || "Lo que buscas no existe.");
      } else {
        Vue.$toast.warning("Lo que buscas no existe.");
      }
    } else if (status === 500) {
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        if (data.message) {
          Vue.$toast.error(data.message);
        } else {
          Vue.$toast.error(
            "Ocurrió un error en nuestros servidores, recargue la pagína e intente de nuevo.",
          );
        }
      }
    } else if (status === 401) {
      auth.removeToken();
      router.push({ name: 'Home' });
    }
    store.dispatch("SET_LOADING", false);
    return Promise.reject(error);
  },
);

Vue.prototype.$axios = axios;
