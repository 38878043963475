import Dashboard from "@/layouts/Dashboard.vue";

const dashboard = [
  {
    path: "/dashboard",
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/Home.vue"),
      },
      {
        path: "demo",
        name: "Dashboard.Demo",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/HomeDemo.vue"),
      },
    ],
  },
];

export default dashboard;
